<template>
  <div class="hero background-blend d-flex flex-column justify-content-center" :style="[{backgroundImage: `url(${image})`}, cssVars]">
    <button @click="showHideNav" v-if="options.showMenu">
      <svg-icon icon="menu" fillColor="#fff" strokeColor="none" class="sticky-logo" width="50px"></svg-icon>
    </button>
    <div class="row justify-content-center" v-if="options.showLogo">
      <div class="row">
        <svg-icon icon="logo-wht-trans-sq" fillColor="#ffffff" strokeColor="none" class="p-2 d-block d-sm-none" :width="`${svgWidth}px`"></svg-icon>
        <svg-icon icon="logo-clr-trans" fillColor="#11999e" strokeColor="none" class="p-0 d-none d-sm-block bg-transparent-white" :width="`${svgWidth}px`" :borderRadius="`50%`"></svg-icon>
      </div>
    </div>
    <div class="row">
      <h3 class="color-white text-center w-100 mt-3">
        {{ options.tagline }}
      </h3>
    </div>
    <div class="gradient-btm-black"></div>
  </div>
</template>
 <!--  -->
<script>
  import { ref, computed, onMounted } from "vue";
  import SvgIcon from './svg-icon.vue'

  export default {
    name: 'hero',
    props: {
      image: {
        type: String,
        required: true
      },
      options: {
        type: Object,
        required: false,
        default: {
          blendMode: 'luminosity',
          backgroundColor: '#11999e',
          tagline: 'Bridging the gap to your full potential',
          showLogo: true
        }
      },
    },
    setup(props) {
      const svgWidth = ref(500)

      const showHideNav = () => {
        let nav = document.querySelector('.nav-drawer')

        if (nav.classList.contains('active')) {
          nav.classList.remove('active')
        }else{
          nav.classList.add('active')
        }
      }

      const cssVars = computed(() => {
        return {
          '--background-image': `url("${props.image}")`,
          '--background-color': props.options.backgroundColor,
          '--background-blend-mode': props.options.blendMode
        }
      })

      const handleResize = () => {
        // Set the max height on the scrollable lists on resize
        let width = window.innerWidth

        if (width < 768) {
          svgWidth.value = 300
        }else{
          svgWidth.value = 500
        }
      }

      onMounted(() => {
        handleResize();
        window.addEventListener('resize', handleResize)
      })

      return {
        showHideNav,
        svgWidth,
        cssVars
      }
    },
    components: {
      SvgIcon
    },
  }
</script>

<style lang="scss" scoped>
  .hero {
    // background-image: var(--background-image) !important;
    background-color: var(--background-color) !important;
    background-blend-mode: var(--background-blend-mode) !important;
    background-attachment: fixed;
     -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);

    h1 {
      font-size: 36px;
      line-height: 36px;
    }
    h2 {
      font-size: 16px;
    }
    h3 {
      font-size: 46px;
      line-height: 46px;
      font-weight: bold;
    }

    button {
      background: none;
      border: none;
      position: absolute;
      top: 32px;
      left: 32px;
      z-index: 10;
      cursor: pointer;
    }

    > div {
      z-index: 9;

      &.gradient-btm-black {
        z-index: 0;
      }
    }

    @media screen and (max-width: 576px) {
      background-attachment: scroll;

      h1 {
        font-size: 26px;
        line-height: 26px;
      }

      h3 {
        font-size: 36px;
        line-height: 36px;
        font-weight: bold;
      }
    }
  }
</style>
