<template>
  <div>
    <nav id="nav-drawer" class="nav-drawer">
      <button @click="close" class="nav-close btn btn-primary btn__sq">X</button>
      <a href="/" title="Home page">
        <svg-icon icon="logo-clr-trans" fillColor="#84BCBC" strokeColor="none" class="p-2" width="200px"></svg-icon>
      </a>
      <ul class="nav-user mb-2" v-if="user.id">
        <hr />
        <li class="nav-li has-submenu row">
          <svg-icon icon="admin" fillColor="#0c7474" strokeColor="none"></svg-icon>
          <a href="/admin" title="Edit" class="d-none d-lg-flex flex-column justify-content-center"><span class="text-uppercase pl-2">{{ user.username }}</span></a>
          <div class="subnav p-2">
            <h2 class="mt-0 pb-1">{{ user.username }}</h2>
            <div class="row">
              <div class="col-6 pl-0 border-right" v-if="user.role === 'admin'">
                <h3>Admin</h3>
                <ul>
                  <li class="subnav-li">
                    <a href="/admin" title="Primary" class="btn btn-tertiary__icon d-flex">
                      <svg-icon icon="admin" classes="pt-0 pb-0 pl-1 pr-1" fillColor="#0c7474" strokeColor="none"></svg-icon>
                      <span class="btn-tertiary__text">Dashboard</span>
                    </a>
                  </li>
                  <li class="subnav-li">
                    <a href="/admin/pages" title="Primary" class="btn btn-tertiary__icon d-flex">
                      <svg-icon icon="dash" classes="pt-0 pb-0 pl-1 pr-1" fillColor="#0c7474" strokeColor="none"></svg-icon>
                      <span class="btn-tertiary__text">Pages</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-6 pr-0">
                <h3>Account</h3>
                <ul>
                  <li class="subnav-li">
                    <a href="/users/edit" title="Home" class="btn btn-tertiary__icon d-flex">
                      <svg-icon icon="account" classes="pt-0 pb-0 pl-1 pr-1" fillColor="#0c7474" strokeColor="none"></svg-icon>
                      <span class="btn-tertiary__text">Account</span>
                    </a>
                  </li>
                  <li class="subnav-li">
                    <a href="/users/sign_out" title="Home" data-method="delete" class="btn btn-tertiary__icon d-flex">
                      <svg-icon icon="logout" classes="pt-0 pb-0 pl-1 pr-1" fillColor="#0c7474" strokeColor="none"></svg-icon>
                      <span class="btn-tertiary__text">Logout</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
        <hr />
      </ul>
      <ul class="nav-ul mb-2">
        <li class="nav-li" 
          v-for="page in pages"
          :key="page.id"
          >
          <a :href="'/' + page.slug" v-bind:title="page.title" class="w-100 row">
            <span class="col-10 nav-li__text">{{ page.title }}</span>
          </a>
        </li>
      </ul>
      <span class="spacer"></span>
      <ul class="nav-ul mb-2" v-if="!user.id">
        <li class="nav-li">
          <a href="/users/sign_in" class="w-100 row">
            <svg-icon icon="enter" classes="col-2 pl-2" fillColor="#0c7474" strokeColor="none"></svg-icon>
            <span class="col-10 nav-li__text">Login</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>
 <!--  -->
<script>

  import SvgIcon from './svg-icon.vue'

  import { onMounted } from "vue"

  export default {
    name: 'nav-drawer',
    props: {
      pages: Array,
      user: Object
    },
    setup(props) {
      const pages = props.pages
      const user = props.user

      const close = () => {
        document.getElementById('nav-drawer').classList.remove('active')
      }

      const handleResize = () => {
        // Set the max height on the scrollable lists on resize
        let width = window.innerWidth
        let nav = document.querySelector('body:not(.application.index) .nav-drawer')
        if (nav) {
          if (width < 992) {
            // nav.classList.remove('active')
          }else{
            nav.classList.add('active')
          }
        }
      }

      onMounted(() => {
        handleResize();
        window.addEventListener('resize', handleResize)
      })

      return {
        close,
        pages,
        user
      }
    },
    components: {
      SvgIcon
    },
  }
</script>

<style lang="scss" scoped>
#nav-drawer {
  z-index: 999;

  .nav-close {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 1.5em;
    font-weight: bold;
  }
}
</style>
