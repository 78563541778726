<template>
  <div id="sticky-nav" :class="[`row mb-1`, classes]">
    <button @click="showHideNav" v-if="options.showMenu">
      <svg-icon icon="menu" fillColor="#fff" strokeColor="none" class="sticky-logo" width="20px"></svg-icon>
    </button>
    <div class="row sticky-nav-menu w-auto align-items-center" v-if="isHome">
      <small class="color-white d-none d-sm-inline-block">On this page:</small>
      <small class="mobile color-white d-flex d-sm-none" @click="showHideMenu">
        On this page 
        <svg-icon icon="chevron" fillColor="none" strokeColor="#fff" class="sticky-logo ml-1" width="20px"></svg-icon>
      </small>
      <a 
        v-for="page in pages"
        :key="page.id"
        @click.prevent="scrollTo(page.slug)"
        class="in-page-link"
        >
        {{ page.title }}
      </a>
    </div>
  </div>
</template>
 <!--  -->
<script>
  import { onMounted } from 'vue'
  import SvgIcon from './svg-icon.vue'

  export default {
    name: 'sticky-nav',
    props: {
      pages: Array,
      classes: String,
      options: Object
    },
    setup(props) {
      const pages = props.pages;
      const isHome = document.querySelectorAll('.application').length > 0;
      const showMenu = props.showMenu;

      const showHideNav = () => {
        let nav = document.querySelector('.nav-drawer')

        if (nav.classList.contains('active')) {
          nav.classList.remove('active')
        }else{
          nav.classList.add('active')
        }
      }

      const showHideMenu = () => {
        let nav = document.querySelector('.sticky-nav-menu')

        if (nav.classList.contains('active')) {
          nav.classList.remove('active')
        }else{
          nav.classList.add('active')
        }
      }

      const scrollTo = (id) => {
        showHideMenu()
        let elm = document.getElementById(id)
        elm.scrollIntoView({behavior: "smooth"})
      }

      onMounted(() => {
        window.onscroll = function() {myFunction()};

        // Get the header
        var header = document.getElementById("sticky-nav");
        var diagram = document.getElementById('diagram');

        // Get the offset position of the navbar
        var sticky = header.offsetTop;
      

        // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
        function myFunction() {
          if (diagram && (window.pageYOffset + 500) > diagram.offsetTop) {
            diagram.classList.add("animate");
          }
          console.log(isHome)
          if (isHome) {
            if (window.pageYOffset > sticky) {
              header.classList.add("sticky");
            } else {
              header.classList.remove("sticky");
            }
          }
        }
      })

      return {
        scrollTo,
        showHideNav,
        showHideMenu,
        showMenu,
        pages,
        isHome
      }
    },
    components: {
      SvgIcon
    },
  }
</script>

<style lang="scss" scoped>
 #sticky-nav {
   justify-content: space-between;
   align-items: center;
   margin-top: -32px;
   margin-bottom: 32px;
   z-index: 998;
   transition: all 0.2s;
   background-color: transparent;
   padding-right: 16px;
   position: absolute;
   right: 0;

   button {
     background: none;
     border: none;
     padding: 4px 8px;
     cursor: pointer;
   }

   .sticky-logo {
     opacity: 0;
   }

   a {
     color: white;
     margin-left: 16px;

     @media screen and (max-width: 576px) { 
       display: none;
     }
   }

   @media screen and (max-width: 576px) {
    small {
      display: none !important;
    }
    &.sticky small.mobile{
      display: flex !important;
    }
    .sticky-nav-menu.active {
      flex-direction: column;
       a {
        display: flex;
        position: absolute;
        background: darken(#0f544d, 5%);
        padding: 12px 16px;
        right: 16px;
        width: 200px;
        justify-content: flex-end;

        &:nth-of-type(1) {
          top: 48px;
        }
        &:nth-of-type(2) {
          top: 88px;
        }
        &:nth-of-type(3) {
          top: 128px;
        }
        &:nth-of-type(4) {
          top: 168px;
        }
       }
     }
   }

   &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    padding: 16px;
    background-color: #11544d;
    box-shadow: 0px 6px 16px rgba(26, 59, 88, 0.15);

    .sticky-logo {
     opacity: 1;
   }
  }
 }
  
</style>
