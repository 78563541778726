<template>
  <div>
    <footer class="pl-3 pr-3 pt-2 pt-lg-3 pb-2 pb-lg-3 pr-lg-5 pl-lg-5 pb-2 row">
      <div class="col-6 footer-left">
        <img :src="image" width="150" />
        <p class="color-white"></p>
      </div>
      <div class="col-6 d-flex flex-row justify-content-end mb-2 footer-right">
        <a 
          v-for="channel in options.channels"
          :key="channel.name"
          :title="channel.name"
          target="_blank"
          >
          <svg-icon :icon="channel.icon" fillColor="#fff" strokeColor="none" class="sticky-logo ml-2" width="50px"></svg-icon>
        </a>
      </div>
      <div class="col d-flex flex-row justify-content-end color-white">
        <p class="color-white">&copy; {{ options.year }} Jane Gilchrist Coaching Ltd - 13334071 </p>
      </div>
    </footer>
  </div>
</template>
 <!--  -->
<script>
  import SvgIcon from './svg-icon.vue'
  import image from "../../images/accreditation.png"

  export default {
    name: 'footer-component',
    props: {
      pages: Array,
      options: Object
    },
    setup(props) {
      const pages = props.pages
      const options = props.options

      return {
        pages,
        options,
        image
      }
    },
    components: {
      SvgIcon
    },
  }
</script>

<style lang="scss" scoped>
  footer {
    .footer-left {
      img {
        max-width: 80%;
      }
    }
  }
 
</style>
