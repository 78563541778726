import { createApp } from 'vue/dist/vue.esm-bundler.js';
import TurbolinksAdapter from 'vue-turbolinks';
import UIComponentLibrary from '@kaybird/ui-component-library'

import * as components from './components'

import '../stylesheets/index.js.erb';

document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById('app');
  if (element != null) {
    const app = createApp({})
    for (const componentName in components) {
      const component = components[componentName]
      app.component(component.name, component)
    }
    app.use(UIComponentLibrary)
    app.use(TurbolinksAdapter)
    app.mount(element)
  }
});
